import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function SecondHospitalNavbar({ setSearchModal }) {
  const [isSticky, setIsSticky] = useState(false);
  const sitedata = useSelector((store) => store.global.loginData);
  const patientLogin = useSelector((state) => state.patient.patientLogin);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="sticky-outer full-dark">
      <div className={`sticky-head ${isSticky ? "header-sticky" : ""}`}>
        <nav className="navbar">
          <div className="basic-container clearfix">
            <ul className="d-flex justify-content-between align-items-center">
              <li className="menu-item">
                <Link to={"/"}>Home</Link>
              </li>
              <li className="menu-item">
                <Link to={"/about"}>About Us</Link>
              </li>
              <li class="dropdown menu-item dropdown-sub">
                <a>Patient Care</a>
                <ul class="dropdown-menu">
                  <li>
                    <Link to={"/doctors"}>Doctors</Link>
                    <Link to={"/specialities"}>Specialties</Link>
                    <Link to={"/facilities"}>Facilities</Link>
                  </li>
                </ul>
              </li>
              <li class="dropdown menu-item dropdown-sub">
                <a>Media</a>
                <ul class="dropdown-menu">
                  <li>
                    <Link to={"/blogs"}>Blogs</Link>
                    <Link to={"/news"}>News & Update</Link>
                    <Link to={"/reviews"}>Reviews</Link>
                    <Link to={"/gallery"}>Gallery</Link>
                  </li>
                </ul>
              </li>
             
              <ul className="nav navbar-items">
                <li className="list-item pl-5 pr-5">
                  <Link
                    to={"/"}
                    className="logo-general d-flex align-items-center gap-1"
                  >
                    <img
                      src={
                        sitedata?.site_logo
                          ? `${sitedata?.site_logo}`
                          : "/assets/img/hospital-static.jpg"
                      }
                      alt="Logo"
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "10px",
                        // marginTop: "5px",
                      }}
                    />
                    {/* <h5 className="mb-0">
                      {sitedata.site_type !== "hospitals" &&
                      sitedata?.site_title?.length > 0 ? (
                        <span>{"Dr. " + sitedata?.site_title}</span>
                      ) : (
                        sitedata?.site_title
                      )}
                    </h5> */}
                  </Link>
                </li>
              </ul>

              <li class="dropdown menu-item dropdown-sub">
                <a>Careers</a>
                <ul class="dropdown-menu">
                  <li>
                    <Link to={"/careers"}>Job Openings</Link>
                    <Link to={"/drop-cv"}>Drop Your CV</Link>
                  </li>
                </ul>
              </li>

             
              <li className="menu-item">
                <Link to={"/feedback"}>Feedback</Link>
              </li>
              <li className="menu-item">
                <Link to={"/contact"}>Contact Us</Link>
              </li>
              <ul>
                <li className="list-item Login-btn-new-theme">
                  {patientLogin ? (
                    <Link to="/patient">My Account</Link>
                  ) : (
                    <Link to="/login">Login</Link>
                  )}
                </li>
              </ul>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default SecondHospitalNavbar;

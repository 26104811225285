import React from "react";
import ScrollToTop from "../Theme2DashboardTabs/ScrollToTop";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function SecondFooter() {
  const sitedata = useSelector((store) => store.global.loginData);
  return (
    <>
      <section
        id="get-quote-section"
        className="get-quote-section bg-theme pad-none pad-top-md-none pad-bottom-md-none relative o-visible index-1"
      >
        {/* <div className="container">
                     <div className="pad-top-35 pad-bottom-30 pe-2 pe-lg-0 ps-lg-0 ps-2">
                       <div className="row">
                         <div className="col-md-12">
                           <h3 className="get-quote text-center font-w-300 ">
                             Secure Your Appointment with Our Expert Doctors Today!{" "}
                             <Link>Get Scheduled</Link>
                           </h3>
                         </div>
                       </div>
                     </div>
                   </div> */}
        <div className="container">
          <div className="b-radius-8 pad-top-50 pad-bottom-45 pe-5 pe-lg-0 ps-lg-0 ps-5">
            <div className="row get-quote-1">
              <div className="col-lg-2 pe-lg-0 ps-lg-5 pb-4 pb-lg-0 align-self-center">
                <div className="quote-icon">
                  <img
                    src="/assets/images/icons/phone-call.png"
                    className="img-fluid thumb ms-0 ms-lg-auto"
                    width="100"
                    height="100"
                    alt="events-img"
                  />
                </div>
              </div>
              <div className="col-lg-6 px-3 px-lg-4 pe-lg-5 br-right">
                <div className="title-wrap mb-0">
                  <div className="section-title mb-0">
                    <h3 className="title mb-3 mb-lg-1 acheivement-bottom-text">
                      {sitedata?.advertisement?.callus_heading ||
                        "Mist is proud to serve you 24/7. Just Call Us when you need"}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 ps-lg-0 pe-lg-3 justify-content-left justify-content-lg-center d-flex align-self-center">
                <div className="contact-info">
                  <h3 className="acheivement-bottom-text">
                    Call Us Anytime:{" "}
                    {sitedata?.advertisement?.callus_number ? (
                      <a href={`tel:${sitedata?.advertisement?.callus_number}`}>
                        {sitedata?.advertisement?.callus_number}
                      </a>
                    ) : (
                      <span>--</span>
                    )}
                  </h3>
                  {sitedata?.permissions?.appointment === "show" && (
                    <div className="get-quote-3 button">
                      <Link
                        className="btn btn-border"
                        target="_blank"
                        title="Download Now"
                        to={"/appointment"}
                      >
                        Book Appointment
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="footer" className="footer bg-grey2 footer-1">
        <div className="footer-widgets">
          <div className="footer-middle-wrap bg-widget">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 widget text-widget">
                  <div className="widget-title">
                    <h3 className="title text-uppercase">About Us</h3>
                  </div>
                  <div className="widget-text margin-bottom-15">
                    <p>
                      {sitedata?.short_description
                        ?.split(" ")
                        .slice(0, 32)
                        .join(" ") +
                        (sitedata?.short_description?.split(" ").length > 32
                          ? "..."
                          : "")}
                    </p>
                    <div className="get-quote-3 button">
                      <Link
                        className="btn btn-border"
                        target="_blank"
                        title="Download Now"
                        to={"/doctors"}
                      >
                        Find Doctors
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 widget text-widget">
                  <div className="widget-title">
                    <h3 className="title text-uppercase">Links</h3>
                  </div>
                  <div className="menu-quick-links footer-links">
                    <ul className="row menu">
                      <li className="col-lg-6 menu-item">
                        <Link to={"/"}>Home</Link>
                      </li>
                      <li className="col-lg-6 menu-item">
                        <Link to={"/about"}>About</Link>
                      </li>
                      <li className="col-lg-6 menu-item">
                        <Link to={"/doctors"}>Doctors</Link>
                      </li>
                      <li className="col-lg-6 menu-item">
                        <Link to={"/gallery"}>Gallery</Link>
                      </li>
                      <li className="col-lg-6 menu-item">
                        <Link to={"/specialities"}>Specialities</Link>
                      </li>
                      <li className="col-lg-6 menu-item">
                        <Link to={"/blogs"}>Blogs</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 widget text-widget">
                  <div className="widget-title">
                    <h3 className="title text-uppercase">Contact Us</h3>
                  </div>
                  <nav>
                    <ul className="contact-info">
                      <li>
                        <i
                          aria-hidden="true"
                          className="ti-headphone theme-color"
                        ></i>
                        <span>
                          <a href={`tel:${sitedata?.site_contact}`}>
                            {sitedata?.site_contact}
                          </a>
                        </span>
                      </li>
                      <li>
                        <i
                          aria-hidden="true"
                          className="ti-email theme-color"
                        ></i>
                        <span>
                          {" "}
                          <a href={`mailto:${sitedata?.site_email}`}>
                            {sitedata?.site_email}
                          </a>
                        </span>
                      </li>

                      <li>
                        <i
                          aria-hidden="true"
                          className="ti-location-pin theme-color"
                        ></i>{" "}
                        <span>
                          {" "}
                          <a>
                            {sitedata?.site_address || "No address provided."}
                          </a>
                        </span>
                      </li>
                    </ul>
                  </nav>

                  <div className="social-icons mb-3">
                    {sitedata?.site_extra?.facebook_link && (
                      <a
                        href={sitedata?.site_extra?.facebook_link}
                        className="social-fb"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="ti-facebook"></span>
                      </a>
                    )}
                    {sitedata?.site_extra?.twitter_link && (
                      <a
                        href={sitedata?.site_extra?.twitter_link}
                        className="social-twitter"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="ti-twitter"></span>
                      </a>
                    )}
                    {sitedata?.site_extra?.instagram_link && (
                      <a
                        href={sitedata?.site_extra?.instagram_link}
                        className="social-instagram"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="ti-instagram"></span>
                      </a>
                    )}
                    {sitedata?.site_extra?.linkedin_link && (
                      <a
                        href={sitedata?.site_extra?.linkedin_link}
                        className="social-instagram"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="ti-linkedin"></span>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright bg-grey">
          <div className="footer-bottom-wrap pad-tb-20">
            <div className="container">
              <div className="row">
                <div className="col-md-12 copyright-section">
                  <ul className="footer-bottom-items pull-left">
                    <li className="nav-item">
                      <div className="nav-item-inner copyright">
                        Copyrights © {new Date()?.getFullYear()} <a>ClinTa</a>. Powered by
                        <a
                          href="https://dright.net/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          D-Right Technologies
                        </a>
                      </div>
                    </li>
                    <ul className="footer-bottom-items footer-menu">
                      <li className="nav-item">
                        <a href="#">Terms of Use</a>
                      </li>
                      <li className="nav-item">
                        <a href="#">Privacy Policy</a>
                      </li>
                    </ul>
                  </ul>
                  <ScrollToTop />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default SecondFooter;

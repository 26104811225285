import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SendPostRequest } from "../http";
import { capitalize } from "@mui/material";
import { Link } from "react-router-dom";
import HeaderWrapper from "./Header/HeaderWrapper";
import FooterWrapper from "./Footer/FooterWrapper";

function Gallery() {
  const sitedata = useSelector((store) => store.global.loginData);

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [carouselImages, setCarouselImages] = useState([]);
  const [my_loader, setMyLoader] = useState(false);
  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Gallery - ClinTa";
    window.scrollTo(0, 0);
    getGalleyData();
  }, []);

  const [isData, setIsData] = useState(false);
  const getGalleyData = async () => {
    setMyLoader(true);
    const response = await SendPostRequest("gallery/list");
    if (!response?.data || response?.data.length < 1) {
      setIsData(true);
    } else {
      setGalleryData(response.data);
      setMyLoader(false);
    }
  };
  return (
    <div className="page-wrapper-inner">
      <div class="page-wrapper-inner">
        <HeaderWrapper />
        <div class="page-title-wrap typo-white">
          <div
            class="page-title-wrap-inner section-bg-img"
            style={{
              backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
            }}
          >
            <span class="black-overlay"></span>
            <div class="container">
              <div class="row text-left">
                <div class="col-md-12">
                  <div class="page-title-inner">
                    <h1 class="page-title mb-0">Gallery</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row portfolio-wrap portfolio-classic-pro py-5 my-2">
            {galleryData.length > 0 ? (
              galleryData.map((item, index) => (
                <div
                  key={index}
                  className={`portfolio-item portfolio-overlay-wrap col-lg-4 col-md-4 col-sm-4 col-xs-6 `}
                >
                  <div className="portfolio-thumb relative">
                    <img
                      src={item.gallery_image}
                      className="img-fluid"
                      alt="gallery thumbnail"
                      style={{ height: "200px", width: "100%" }}
                    />
                    <div className="portfolio-wrap">
                      <div className="portfolio-details-overlay typo-white gallery-heading-mob">
                        <h3 className="mb-2">
                          <Link
                            to={`/gallery/${item?.uid}`}
                            rel="noopener noreferrer"
                            className="gallery-head-title"
                          >
                            {item?.gallery_title
                              .split(" ")
                              .slice(0, 10)
                              .join(" ")}
                          </Link>
                        </h3>
                      </div>
                      <div className="portfolio-icons">
                        <Link
                          to={`/gallery/${item?.uid}`}
                          className="link-icon"
                          rel="noopener noreferrer"
                        >
                          <i className="icon-link"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center">
                <h3>No Data Found</h3>
              </div>
            )}
          </div>
        </div>

        <FooterWrapper />
      </div>
    </div>
  );
}

export default Gallery;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";

function HospitalMobileNavbar({ toggleNav, setToggleNav, setSearchModal }) {
  const [openDropdown, setOpenDropdown] = useState(-1);
  const [openInnerDropdown, setOpenInnerDropdown] = useState(-1);
  const [isSticky, setIsSticky] = useState(false);
  const sitedata = useSelector((store) => store.global.loginData);
  const patientLogin = useSelector((state) => state.patient.patientLogin);

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? -1 : index);
  };
  const toggleInnerDropdown = (index) => {
    setOpenInnerDropdown(openInnerDropdown === index ? -1 : index);
  };

  const navbarItems = [
    {
      name: "Home",
      url: "/",
      submenu: [],
    },
    {
      name: "About Us",
      url: "/about",
      submenu: [],
    },
    {
      name: "Patient Care",
      url: "",
      submenu: [
        { name: "Doctors", url: "/doctors" },
        { name: "Specialties", url: "/specialities" },
        { name: "Facilities", url: "/facilities" },
      ],
    },
    {
      name: "Media",
      url: "",
      submenu: [
        { name: "Blogs", url: "/blogs" },
        { name: "News & Update", url: "/news" },
        { name: "Reviews", url: "/reviews" },
        { name: "Gallery", url: "/gallery" },
      ],
    },
    {
      name: "Careers",
      url: "",
      submenu: [
        { name: "Job Openings", url: "/careers" },
        { name: "Drop Your CV", url: "/drop-cv" },
      ],
    },

    {
      name: "Feedback",
      url: "/feedback",
      submenu: [],
    },
    {
      name: "Contact Us",
      url: "/contact",
      submenu: [],
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="mobile-header bg-white typo-dark d-block d-md-none">
        <div className="mobile-header-inner">
          <div className="sticky-outer">
            <div className={`sticky-head ${isSticky ? "header-sticky" : ""}`}>
              <div className="basic-container d-flex justify-content-between align-items-center">
                <ul className="nav mobile-header-items show-opacity">
                  <li className="text-center">
                    <Link
                      to={"/"}
                      className="logo-general d-flex align-items-center gap-1 justify-content-center"
                    >
                      {/* <img
                        src={
                          sitedata?.site_logo
                            ? `${sitedata?.site_logo}`
                            : "/assets/img/hospital-static.jpg"
                        }
                        alt="Logo"
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "10px",
                          margin: "0px",
                        }}
                      /> */}
                      <div
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "10px",
                          margin: "0px",
                          backgroundImage: `url(${
                            sitedata?.site_logo
                              ? sitedata?.site_logo
                              : "/assets/img/hospital-static.jpg"
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      />

                      <h5 className="mb-0">
                        {sitedata?.site_type !== "hospitals" &&
                        sitedata?.site_title?.length > 0 ? (
                          <span>{"Dr. " + sitedata?.site_title}</span>
                        ) : (
                          sitedata?.site_title
                        )}
                      </h5>
                    </Link>
                  </li>
                </ul>
                <ul className="nav mobile-header-items">
                  <li className="nav-item">
                    <span
                      className="zmm-toggle img-before"
                      onClick={() => setToggleNav(true)}
                    >
                      <i className="ti-menu"></i>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="zmm-wrapper"
        style={{ left: toggleNav ? "0px" : "-100%" }}
      >
        <div
          className="zmm-inner bg-white typo-dark"
          style={{ maxHeight: "100vh", overflowY: "auto" }}
        >
          <div className="text-center mobile-logo-part margin-bottom-30">
            <div className="d-flex align-items-center gap-1 justify-content-between">
              <Link
                to={"/"}
                className="logo-general d-flex align-items-center gap-1 justify-content-center"
              >
                <img
                  src={
                    sitedata?.site_logo
                      ? `${sitedata?.site_logo}`
                      : "/assets/img/hospital-static.jpg"
                  }
                  alt="Logo"
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "10px",
                    margin: "0px",
                  }}
                />
                <h5 className="mb-0">
                  {sitedata.site_type !== "hospitals" &&
                  sitedata?.site_title?.length > 0 ? (
                    <span>{"Dr. " + sitedata?.site_title}</span>
                  ) : (
                    sitedata?.site_title
                  )}
                </h5>
              </Link>
              <span className="zmm-close " onClick={() => setToggleNav(false)}>
                <IoCloseSharp />
              </span>
            </div>
          </div>
          <div className="zmm-main-nav">
            <ul className="navbar-main theme-mobile-menu">
              {navbarItems?.map((item, index) => (
                <li
                  key={index}
                  className="dropdown-sub pos-static icon-hide-1024"
                >
                  {item?.submenu?.length > 0 ? (
                    <span
                      onClick={() => {
                        toggleDropdown(index);
                        setOpenInnerDropdown(-1);
                      }}
                    >
                      {item?.name}
                    </span>
                  ) : (
                    <Link to={item?.url}>{item?.name}</Link>
                  )}
                  <ul
                    className="sub-nav"
                    style={{
                      display: openDropdown === index ? "block" : "none",
                    }}
                  >
                    {item?.submenu?.map((subItem, i) => (
                      // <li key={index}>{subItem?.name}</li>
                      <li key={i} className="">
                        {subItem?.submenu ? (
                          <span onClick={() => toggleInnerDropdown(i)}>
                            {subItem?.name}
                          </span>
                        ) : (
                          <Link to={subItem?.url}>{subItem?.name}</Link>
                        )}
                        <ul
                          className="child-dropdown-menu sub-nav"
                          style={{
                            display: openInnerDropdown === i ? "block" : "none",
                          }}
                        >
                          {subItem?.innersubmenu &&
                            subItem?.innersubmenu?.map((menu, no) => (
                              <li key={no}>
                                <Link to={menu?.url}>{menu?.name}</Link>
                              </li>
                            ))}
                        </ul>
                        {subItem?.innersubmenu && (
                          <span
                            className={`zmm-dropdown-toggle ti-plus d-block ${
                              openInnerDropdown === i ? "ti-minus" : ""
                            }`}
                            onClick={() => toggleInnerDropdown(i)}
                          ></span>
                        )}
                      </li>
                    ))}
                  </ul>
                  {item?.submenu?.length > 0 && (
                    <span
                      className={`zmm-dropdown-toggle ti-plus ${
                        openDropdown === index && "ti-minus"
                      }`}
                      onClick={() => toggleDropdown(index)}
                    ></span>
                  )}
                </li>
              ))}
            </ul>
            <div className=" Login-btn-new-theme ">
              {patientLogin ? (
                <Link to="/patient">My Account</Link>
              ) : (
                <Link to="/login">Login</Link>
              )}
            </div>
          </div>
          {/* <div className="search-form-wrapper margin-top-30">
            <form className="search-form" role="search">
              <div className="input-group add-on">
                <input
                  className="form-control"
                  placeholder="Search for.."
                  name="srch-term"
                  type="text"
                />
                <div className="input-group-btn">
                  <button className="btn btn-default search-btn" type="submit">
                    <i className="ti-arrow-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default HospitalMobileNavbar;
